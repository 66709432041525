exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-natural-gas-js": () => import("./../../../src/pages/natural-gas.js" /* webpackChunkName: "component---src-pages-natural-gas-js" */),
  "component---src-pages-one-tree-planted-js": () => import("./../../../src/pages/one-tree-planted.js" /* webpackChunkName: "component---src-pages-one-tree-planted-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-fixed-green-js": () => import("./../../../src/pages/products/fixed-green.js" /* webpackChunkName: "component---src-pages-products-fixed-green-js" */),
  "component---src-pages-products-fixed-js": () => import("./../../../src/pages/products/fixed.js" /* webpackChunkName: "component---src-pages-products-fixed-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-var-wholesale-js": () => import("./../../../src/pages/products/var-wholesale.js" /* webpackChunkName: "component---src-pages-products-var-wholesale-js" */),
  "component---src-pages-products-var-wholesale-rec-js": () => import("./../../../src/pages/products/var-wholesale-rec.js" /* webpackChunkName: "component---src-pages-products-var-wholesale-rec-js" */),
  "component---src-pages-products-wacog-js": () => import("./../../../src/pages/products/wacog.js" /* webpackChunkName: "component---src-pages-products-wacog-js" */),
  "component---src-pages-terminos-y-condiciones-de-los-servicios-de-gas-natural-js": () => import("./../../../src/pages/terminos-y-condiciones-de-los-servicios-de-gas-natural.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-de-los-servicios-de-gas-natural-js" */),
  "component---src-pages-terms-conditions-of-natural-gas-services-js": () => import("./../../../src/pages/terms-conditions-of-natural-gas-services.js" /* webpackChunkName: "component---src-pages-terms-conditions-of-natural-gas-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

